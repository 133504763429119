import React from "react"
import { Container, Button } from "react-bootstrap"
import {graphql} from 'gatsby'
import Img from "gatsby-image"

import SEO from "../components/seo"
import Layout from '../components/Layout/Layout'
import { clickEvent } from "../utils/events"

const ContactSuccess = props => (
  <Layout>
    <SEO title="Thank you!" />
    <Container
      style={{
        maxWidth: "900px",
        margin: "auto",
        textAlign: "center",
        padding: "100px 10%",
        minHeight: "100%",
        fontSize: "1.2em",
        lineHeight: "1.5em",
        color: "#315550",
      }}
    >
      <Img
        fluid={props.data.heroImage.image.fluid}
        alt="thank you!"
        style={{
          maxWidth: "90%",
          margin: "auto",
          border: "solid #DDD 1px",
          boxShadow: "0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.5)",
          borderRadius: "20px",
        }}
      />
      <h3 style={{ marginTop: "20px" }}>Thank you for reaching out!</h3>
      <p>We will contact you by email within 24 hours.</p>
      <p>We look forward to speaking with you.</p>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button
          href="/"
          size="lg"
          className="btn-gridfabric"
          onClick={() => clickEvent("Contact Success Page", "Link to Home")}
        >
          Go Home
        </Button>
      </div>
    </Container>
  </Layout>
)

export default ContactSuccess

export const contactSuccessQuery = graphql`
         query {
           heroImage: contentfulWebsiteImages(
             imageId: { eq: "indexHeroImage" }
           ) {
             image {
               fluid {
                 ...GatsbyContentfulFluid_withWebp_noBase64
               }
             }
           }
         }
       `